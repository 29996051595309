<template>
  <div class="team-feature">
    <div class="top">J&F Development Outsourcing Center</div>
    <div class="center">
      <div class="left">
        <img :src="require('/src/assets/img/odc/team-feature.png')" />
      </div>
      <div class="right">
        <img :src="require('/src/assets/img/odc/co-operating.png')" />

        <!-- <div class="row">
          1. Co-operating with several research and design institutes and
          universities, inherits big pool of experts and consultants to achieve
          highly professional analysis and design on the target application
          scenarios.
        </div>
        <div class="row">
          2. Adopting a modified agile development procedure, namely the Agile
          Factory, can be more adaptive and scalable according to the project
          volume.
        </div>
        <div class="row">
          3. Building up Dev sub-teams with a team of highly skilled engineers
          (respond to design, core algorithm development and quality control),
          and his/her assistants (normal coder), to achieve high coding quality
          as well as a low development cost.
        </div>
        <div class="row">
          4. Deploy measures to safeguard intellectual properties, including
          blocked and monitored office with restricted access control, remote
          on-line development environment and security SOPs to ensure an intact
          E2E confidentiality.
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mobi",
};
</script>

<style lang="less" scoped>
.team-feature {
  display: flex;
  flex-direction: column;
  font-size: 2.5vh;
  font-family: Helvetica, Helvetica-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  line-height: 2.99vh;
  letter-spacing: 1.69px;
  height: 100%;
  width: 100%;
  padding: 0 1vw 0 3.48vw;
  .top {
    font-size: 3.33vh;
    color: #f98a8a;
    line-height: 4.03vh;
    letter-spacing: 0.117vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 6.02vh;
  }
  .center {
    flex: 1;
    display: flex;
    .left img {
      width: 29.47vw;
    }
    .right {
      flex: 1;
      height: 100%;
      margin-left: 3.39vw;
      img {
        width: 95%;
      }
      .row {
        display: flex;
        align-items: center;
        margin: 2.5vh 0px;
      }
    }
  }
}
</style>
