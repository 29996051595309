<template>
  <div class="confidentiality-restriction">
    <img :src="require('/src/assets/img/odc/cr.png')" />
    <a
      style="pos"
      class="down"
      :href="require('/src/assets/download.ttf')"
      download="J&F软件研发保密制度.pdf"
      >download</a
    >
    <!-- <div class="block">
      <div class="title">Dedicated Site</div>
      <div class="context">
        <div class="row">
          <div class="serial">1.</div>
          <div>
            A dedicated working site can be rented as required based on project;
          </div>
        </div>
        <div class="row">
          <div class="serial">2.</div>
          <div>
            24-hour CCTV monitor system is installed, and project leader can
            check the real-time on-line remotely;
          </div>
        </div>
        <div class="row">
          <div class="serial">3.</div>
          <div>
            AI-based video analysis technology is deployed to check some
            sensitive actions (e.g. using mobile phone recording computer
            monitor);
          </div>
        </div>
        <div class="row">
          <div class="serial">4.</div>
          <div>Fingerprint security access system.</div>
        </div>
      </div>
    </div>
    <div class="block">
      <div class="title">Remotely Cloud Coding</div>
      <div class="context">
        <div class="row">
          <div class="serial">1.</div>
          <div>
            Set up a set of private cloud servers, where all coding should be
            done and code should be stored; local coding and local code download
            is prohibited;
          </div>
        </div>
        <div class="row">
          <div class="serial">2.</div>
          <div>
            The coding computers are customized with a limited I/O interface
            (normally only keyboard/mouse and monitor is allowed);
          </div>
        </div>
        <div class="row">
          <div class="serial">3.</div>
          <div></div>
          The coding computers can only visited the necessary websites or IP
          addresses pre-registered for working purpose. An extra internet
          browsing computer can be used,but with a closed monitoring.
        </div>
      </div>
    </div>
    <div class="block">
      <div class="title">Confidentiality SOPS</div>
      <div class="context">
        <div class="row">
          <div class="serial">1.</div>
          <div>
            All actions done on the coding computers and internet browsing
            computers and internet browsing;
          </div>
        </div>
        <div class="row">
          <div class="serial">2.</div>
          <div>
            Engineers,except for IT administrator, are not allowed to install
            any software nor hardware; all installation is based on a
            pre-registered scheme;
          </div>
        </div>
        <div class="row">
          <div class="serial">3.</div>
          <div>
            The attached softear development confidentiality guidance should be
            closely followed.
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "mobi",
};
</script>

<style lang="less" scoped>
.confidentiality-restriction {
  display: flex;
  font-size: 2.5vh;
  font-family: Helvetica, Helvetica-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  line-height: 2.99vh;
  letter-spacing: 1.69px;
  height: 100%;
  width: 100%;
  padding: 2vh 2.8vw;
  .down {
    position: absolute;
    top: 72vh;
    right: 5vw;
    color: #c3c3c3;
    text-decoration: underline;
    &:hover{
     
    }
    &:active{
      opacity: 0.7;
    }
  }
  img {
    width: 100%;
    height: 85%;

    box-sizing: border-box;
  }
  .block {
    flex: 1;
    margin: 0px 2.1vw;
    display: flex;
    flex-direction: column;
    .title {
      background: #000;
      width: 100%;
      height: 7.6vh;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 2vh;
      font-size: 2.78vh;
      line-height: 3.33vh;
      letter-spacing: 1.88px;
    }
    .row {
      display: flex;
      .serial {
        margin-right: 1vw;
      }
    }
  }
}
</style>
