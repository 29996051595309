<template>
  <div class="st-sid">
    <div class="top">
      Establish a comprehensive experts / consultants resource network, in which
      our experts / consultants are from credible institutes and universities,
      such as below:
    </div>
    <div class="table">
      <!-- <el-table :data="tableData" border style="width: 100%">
        <el-table-column
          v-for="col of myColumns"
          :label="col.displayName"
          :property="col.columnName"
          :key="col.columnName"
          :width="col.width"
          align="left"
        >
        </el-table-column>
      </el-table> -->
       <img :src="require('/src/assets/img/odc/experts-consultants.png')" />
    </div>
  </div>
</template>

<script>
export default {
  name: "mobi",
  data() {
    return {
      myColumns: [
        { displayName: "Institute Name", columnName: "1", width: "490" },
        { displayName: "Specialized Fields", columnName: "2" },
      ],
      tableData: [
        {
          1: "China Southwest Geotechnical Investigation & Design Institute",
          2: "Municipal,drainage,gas,heat,bridges,tunnels,landscape architecture",
        },
        {
          1: `Chengdu Municipal Engineering
          Design & Research Institute`,
          2: "Municipal,architecture,landscape architecture,urban and rural planning,rail transit,plant station,etc.",
        },
        {
          1: `Sichuan Communication Research
          Planning & Designing CO.,LTD`,
          2: "Communication network planning/survey/consulting/design;\n Construction engineering consulting design;\n Operator IT support system planning/survey/consulting/design.",
        },
        {
          1: "Southwest Jiaotong University",
          2: "Traffic engineering,transportation,electrical engineering and automation,etc.",
        },
        {
          1: "University of Electronic Science and Technology of China",
          2: "Communication,Electronic Engineering,Software,Big Data,AI,etc",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.st-sid {
  display: flex;
  flex-direction: column;

  font-size: 2.5vh;
  font-family: Helvetica, Helvetica-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  line-height: 2.99vh;
  letter-spacing: 1.69px;
  height: 100%;
  width: 100%;
  padding: 25px 2.92vw;
  img{
    width: 100%;
  }
  .top {
    padding: 0 4.69vw;
    margin-bottom: 4.84vh;
  }
  /deep/ .el-table {
    background-color: #00000000;
    tr {
      background-color: #00000000;
      font-size: 2.5vh;
      font-family: Helvetica, Helvetica-Regular;
      font-weight: 400;
      text-align: left;
      color: #ffffff;
      line-height: 2.99vh;
      letter-spacing: 1.69px;
    }
    th.el-table__cell {
      background-color: #000000;
    }
    .cell {
      word-break: break-word;
      white-space: pre-line;
    }
    .el-table__cell {
      padding: 1.4vh 0;
    }
    th.el-table__cell {
      padding: 2.4vh 0;
    }
    th {
      border-bottom-width: 3px;
    }
  }
}
</style>
