<template>
  <div class="smart-traffic">
    <tabs :list="tablist" v-model="sel"></tabs>
    <div class="main">
      <teamVue v-if="sel === 0"></teamVue
      ><expertsCVue v-else-if="sel === 1"></expertsCVue>
      <HSEVue v-else-if="sel === 2"></HSEVue>
      <confidentialityRVue v-else-if="sel === 3"></confidentialityRVue>
      <workflowVue v-else-if="sel === 4"></workflowVue>
      <generalAgileVue v-else-if="sel === 5"></generalAgileVue>
    </div>
  </div>
</template>

<script>
import teamVue from "./team.vue";
import expertsCVue from "./experts-consultants.vue";
import tabs from "/src/components/tabs/index.vue";
import HSEVue from "./HSE.vue";
import confidentialityRVue from "./confidentiality-restriction.vue";
import workflowVue from "./work-flow.vue";
import generalAgileVue from "./general-agile.vue";
export default {
  components: {
    teamVue,
    expertsCVue,
    HSEVue,
    confidentialityRVue,
    workflowVue,
    generalAgileVue,
    tabs,
  },
  data() {
    return {
      sel: 0,
      tablist: [
        {
          title: "Team Features",
        },
        {
          title: "Experts/Consultants Pool ",
        },
        {
          title: "HSE Dev Sub-teams",
        },
        {
          title: "Confidentiality Restriction",
        },
        {
          title: "Procedure",
        },
        // {
        //   title: "General Agile Dev Workflow",
        // },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.smart-traffic {
  display: flex;
  width: 100%;
  height: 100%;
  background: url("../../assets/img/ODC.png") center center;
  background-size: cover;
  .main {
    flex: 1;
    height: 100%;
  }
  .height-light {
    width: 100%;
    height: 18.75px;
    background: rgba(249, 0, 0, 0.3);
  }

  
}
</style>
