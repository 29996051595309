<template>
  <div class="work-flow">
    <img :src="require('/src/assets/img/odc/general-agile.png')" />
  </div>
</template>

<script>
export default {
  name: "mobi",
};
</script>

<style lang="less" scoped>
.work-flow {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  padding: 1vh 3.48vw 0;

  img {
    width: 100%;
  }
}
</style>
