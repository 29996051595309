<template>
  <div class="st-sid">
    <div class="table">
       <img :src="require('/src/assets/img/odc/HSE-sub-team.png')" />
      <!-- <el-table :data="tableData" border style="width: 100%">
        <el-table-column
          v-for="col of myColumns"
          :label="col.displayName"
          :property="col.columnName"
          :key="col.columnName"
          :width="col.width"
          align="left"
        >
        </el-table-column>
      </el-table> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "mobi",
  data() {
    return {
      myColumns: [
        { displayName: "Core HSE", columnName: "1", width: "250" },
        { displayName: "Area of Expertise", columnName: "2", width: "200" },
        { displayName: "Professional Skills", columnName: "3" },
      ],
      tableData: [
        {
          1: "Hong Kuncheng",
          2: "Backend Dev",
          3: "Java/Python/C++/Data Structure and Algo/Cloud Processing,etc.",
        },
        {
          1: "Xie Fangjian",
          2: "Frontend Dev",
          3: "HTML/CSS/JavaScript/Web Structure/UI Design/Web Standard,etc.",
        },
        {
          1: "Huang Guang",
          2: "Data Analysis",
          3: "Data Processing/Statistics Analysis/Machine Learning/Deep Learning/Data Virtualization,etc.",
        },
        {
          1: "Fang Yonghong",
          2: "loT System",
          3: "loT Protocol/Sensing Device/Embedded Syetem/Data Collection/Data Communication/Data Mining,etc.",
        },
        {
          1: "Yu Xiang",
          2: "Database System",
          3: "Database System/Data Structure and Algo/SQL/NoSQL/Middleware Development,etc.",
        },
        {
          1: "Zhang Ruiqian",
          2: "Data Security",
          3: "Network Security/Data Security/Application Security/Penetration Testing/Security Strategy,etc.",
        },
        {
          1: "Liu Ming",
          2: "AI/Big Data",
          3: "Enterprise Digitalization/Data Governance/Data Management Planning/Data Operation Management/Data Platform Construction/Artificial Intelligence Application/Customer Portrait/Supplier Portrait,etc.",
        },
        {
          1: "Liang Zhun",
          2: "Big Data",
          3: "Big Data Collection/Storage/processing/Application Solutions",
        },
        {
          1: "Kang Wei",
          2: "Android Dev",
          3: "Android",
        },
         {
          1: "Shi Yu",
          2: "IOS Dev",
          3: "Swift、ST、SFC、IOS SDK、Objective-C",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.st-sid {
  display: flex;
  flex-direction: column;

  font-size: 2.5vh;
  font-family: Helvetica, Helvetica-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  line-height: 2.99vh;
  letter-spacing: 1.69px;
  height: 100%;
  width: 100%;
  padding: 25px 2.92vw;
  img{
    width: 100%;
  }
  .top {
    padding: 0 4.69vw;
    margin-bottom: 4.84vh;
  }
  /deep/ .el-table {
    background-color: #00000000;
    tr {
      background-color: #00000000;
      font-size: 1.8vh;
      font-family: Helvetica, Helvetica-Regular;
      font-weight: 400;
      text-align: left;
      color: #ffffff;
      line-height: 2.99vh;
      letter-spacing: 1.69px;
    }
    th.el-table__cell {
      background-color: #000000;
    }
    .cell {
      word-break: break-word;
      white-space: pre-line;
    }
    .el-table__cell {
      padding: 0.4vh 0;
    }
    th.el-table__cell {
      padding: 2.4vh 0;
    }
    th {
      border-bottom-width: 3px;
      font-size: 2.5vh;
    }
  }
}
</style>
